<template>
  <v-app>
    <v-container fluid>
      <v-row class="d-flex align-start mx-5">
        <v-col class="col-6 px-5">
          <v-img
            :src="require(`@/assets/artwork/1200px/${id}_1200px.png`)"
            contain
            class="tableau"
            style="position:fixed"
            @click="overlay = !overlay"
          />
        </v-col>
        <v-col class="col-6 mt-5 px-5" style="margin-bottom:100px"
          >
          <Message
            content="Bonjour, Je suis Edgart, votre assistant virtuel."
            from="edgard"
            icon="icon01.png"
            class="discussion"/>
          <Message
            v-for="message in messageList"
            v-bind:key="message.id"
            :content="message.content"
            :from="message.from"
            :icon="message.icon"
            :color="message.color"
            :note="message.note"
            :possiblesResp="message.possiblesResp"
            :vote="message.vote"
            :userquestion="message.userquestion"
            class="discussion"
          ></Message>
          <div id="message-bottom"></div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-form @submit.prevent="onSubmit">
            <v-row>
              <v-col class="col-lg-6 col-xs-6">
                <v-text-field
                  v-model="message"
                  outlined
                  clearable
                  class="discussion"
                  placeholder="Posez votre question"
                  type="text"
                  height="70"
                  style="bottom:0; position:fixed; right:2vw; width:45vw"
                  background-color=primary
                >
                  <template v-slot:append>
                    <v-btn
                      @click.prevent="buttonSendMessage()"
                      type="submit">
                      <v-icon>
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-overlay :value="overlay">
            <v-img
              :src="require(`@/assets/artwork/full_size/${id}_2500px.png`)"
              contain
              height=85vh
              width=100vw
              @click="overlay = !overlay"
            >
            </v-img>
          </v-overlay>
    </v-container>
  </v-app>
</template>

<script>
import { reactive, ref } from "@vue/composition-api";
import Message from "./Message";
import uuid from "uuid/v4";
import axios from "axios";
import { scrollToBottom } from "@/utils";
import urlChatBot from "../url.config.js";

export default {
  name: "Edgart",
  components: { Message },
  props: ['id'],
  
  data(){
    return {
      overlay: false,
    }
  },
  setup(props) {
    const message = ref("");
    const messageList = reactive([]);

    async function buttonSendMessage() {
      await userMessage();
      scrollToBottom();
      await edgartMessage();
      scrollToBottom();
    }
    async function callEdgard() {
      let url = urlChatBot.root + urlChatBot.endpoint.ask
      let payload = {questions: [message.value], artwork: props.id };
      let response = await axios({
        url,
        method: 'post',
        data: payload,
        raxConfig: {
          retry: 3,
          retryDelay: 4000
        }
      });
      let result = response.data;
      return result;
    }
    
    async function userMessage() {
      messageList.push({
        content: message.value,
        id: uuid(),
        from: "user",
        icon: "icon02.png",
        vote: false,
      });
    }
    async function edgartMessage() {
      const edgardResponse = await callEdgard();
      messageList.push({
        id: uuid(),
        from: "edgard",
        icon: "icon01.png",
        possiblesResp: edgardResponse,
        vote: true,
        userquestion: message.value,
      });
      message.value = "";
    }
    return {
      message,
      messageList,
      buttonSendMessage,
      urlChatBot,
    };
  },
};
</script>
<style>
  .description{
    font-family: "TSTARBold";
    font-size: 20px;
  }
  .discussion{
    font-family: "TSTARRegular";
    font-size: 20px;
  }
   div.container.discussion.user > div {
     background-color: var(--v-userbg-base);
    color: var(--v-usertxt-base);
   }
    .theme--dark.v-card {
     background-color: var(--v-edgartbg-base);
   }
    div.container.discussion.edgard > div > div {
     color: var(--v-primary-base);
   } 
   div.container.discussion.user > div > div {
     color: var(--v-usertxt-base);
   }
   .theme--dark.v-card > .v-card__text, .theme--dark.v-card .v-card__subtitle {
    color: var(--v-primary-base);
   }
   .user > .v-card .v-card__text{
    background-color: var(--v-userbg-base);
    color: var(--v-usertxt-base);
   }
   .user {
     display:flex;
     margin: 1em 0;
   }
</style>
