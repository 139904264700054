import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
      options: { customProperties: true },
      dark:true,
    themes: {
      light: {
        primary: '#1D1D1D',
        secondary: '#b0bec5',
        anchor: '#8c9eff',
        edgartbg: '#F0F0F0',
        userbg: '#3E3E3E',
        usertxt: '#F0F0F0',
      },
      dark: {
        primary: '#131313',
        secondary: '#3E3E3E',
        edgartbg: '#F0F0F0',
        userbg: '#3E3E3E',
        usertxt: '#F0F0F0',
      }
    },
  },
})

export default vuetify