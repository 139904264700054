import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import Edgart from "../components/Edgart.vue";
import Gallery from "../components/Gallery.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/edgart/:id",
    name: "edgart",
    component: Edgart,
    props: true,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery,
  }
];

const router = new VueRouter({
  routes,
});

export default router;
