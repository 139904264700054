<template>
  <v-app>
    <Appbar
      class="mb-4">
      <div>
        <v-toolbar-title>Bienvenue sur Edgart</v-toolbar-title>
      </div>
    </Appbar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Appbar from './components/Appbar.vue'
export default {
  name: "App",
  components: { Appbar },

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Cubano";
  src: local("Cubano"),
  url(./assets/fonts/Cubano-Regular.otf) format("truetype");
}
@font-face {
  font-family: "TSTARRegular";
  src: local("TSTARRegular"),
  url(./assets/fonts/TSTAR\ Mono\ Round\ Regular.ttf) format("truetype");
}
@font-face {
  font-family: "TSTARLight";
  src: local("TSTARLight"),
  url(./assets/fonts/TSTAR\ Mono\ Round\ Light.ttf) format("truetype");
}
@font-face {
  font-family: "TSTARBold";
  src: local("TSTARBold"),
  url(./assets/fonts/TSTAR\ Mono\ Round\ Bold.ttf) format("truetype");
}
.v-application {
  font-family: "TSTARRegular";
}
.toolbar-title{
  font-family: "TSTARBold";
}
  .date {
    text-align: right;
    font-size: 10px;
  }
  .user.date {
    text-align: left;
    font-size: 10px;
  }
  html{
  scroll-behavior: smooth;
}
/* gestion de l'affichage de la date sur chaque message*/

/* affichage des icondes "edgard" et "utilisateur" de chaque côté des messages */
.edgard .avatar , .artwork .avatar {
    grid-column: 2;
    height: 50px;
    margin: 5px;
    border-radius: 5px;
  }
  .user {
    display: grid;
    flex-direction: row-reverse;
  }
  
  .user .message {
    grid-column: 2;
  }
  .user .avatar {
    grid-column: 3;
    height: 50px;
    margin: 5px;
    border-radius: 5px;
  }

  /*gestion de la taille du tableau*/

  .tableau{
    height: 95vh;
    width: 45vw;
    position:fixed;
  }

</style>