<template>
  <v-container>
    <v-row v-bind:class="[from]" style="flex-wrap:inherit">
    <img class="avatar" :src="icon" alt="avatar" />
    <v-card :color="color">
      <v-card-text>
        <span class="msg" v-html="currentMessage"></span>
        <div class="date">{{ moment().format("DD-MM-YYYY à HH:mm") }}</div>
        <div class="vote" v-if="shouldIDisplayVote">
          <v-btn icon color="green" @click="voteFeedback(1)">
            <v-icon>mdi-thumb-up</v-icon>
          </v-btn>
          <v-btn icon color="deep-orange" @click="voteFeedback(-1)">
            <v-icon>mdi-thumb-down</v-icon>
          </v-btn>
        </div>
        <div class="date" v-if="votepositif">Merci de votre vote</div>
      </v-card-text>
    </v-card>
    
    <v-fab-transition v-if="shouldIDisplayMore">
      <v-btn
        v-show="!showMore"
        color="pink"
        dark
        fab
        @click="showMore = !showMore"
        class="showmorebtn"
      >
        <v-icon>mdi-lightbulb-on-outline</v-icon>
        {{ hidden ? currentMore : '' }}
      </v-btn>
    </v-fab-transition>
      
    </v-row>

    <v-card :color="color" v-if="showMore" class="mt-5 col-3 ml-12" style="max-width:fit-content">
      <v-card-text>
        <span class="msg" v-html="currentMore"></span>
        <div class="date">{{ moment().format("DD-MM-YYYY à HH:mm") }}</div>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import moment from "moment";
import { scrollToBottom } from "@/utils";
import axios from "axios";
import { ref, computed, onMounted, toRefs, } from "@vue/composition-api";
import urlChatBot from "../url.config.js";
export default {
  name: "Message",
  props: [
    "from",
    "icon",
    "color",
    "note",
    "possiblesResp",
    "vote",
    "content",
    "userquestion",
  ],
  methods: { moment },
  data: () => ({
    showMore: false,
  }),
  setup(props) {
    const { content, possiblesResp, vote, userquestion } = toRefs(props);
    const currEdgardResp = ref({});
    const isThereAResponse = ref(true);
    const votepositif = ref(false);
    onMounted(() => {
      if (possiblesResp.value !== undefined) {
        // Le back envoie les réponses triés selon le score du plus haut au plus bas
        currEdgardResp.value = possiblesResp.value.shift();
      }
    });
    const currentMessage = computed(function () {
      if (content.value !== undefined) {
        return content.value;
      } else {
        if(currEdgardResp.value.answer != undefined){
          currEdgardResp.value.answer = removeDiacritics(currEdgardResp.value.answer);
        }
        return currEdgardResp.value.answer;
      }
    });
    const currentMore = computed(function () {
      return currEdgardResp.value.more;
    });
    const shouldIDisplayVote = computed(
      () => vote.value && isThereAResponse.value && currEdgardResp.value.default == false
    );
    const shouldIDisplayMore = computed(
      () => isThereAResponse.value && currEdgardResp.value.default == false && currEdgardResp.value.more != 'nan'
    );
    function voteFeedback(score) {
      let url = urlChatBot.root + urlChatBot.endpoint.feedback;
      let payload = {
          artwork: currEdgardResp.value.artwork,
          question: userquestion.value,
          knowledge: currEdgardResp.value.answer,
          score: score,
          historyId: currEdgardResp.value.uuid,
          default: currEdgardResp.value.default,
        };
      let finalNote = axios({
        url,
        method: 'post',
        data: payload,
        raxConfig: {
          retry: 3,
          retryDelay: 4000
        }
      });
      votepositif.value = score == 1 ? true : false;
      if(score == 1){
        isThereAResponse.value = false;
        return currEdgardResp.value, finalNote;
      } else{
        if (possiblesResp.value === undefined) {
          return;
        }
        let nextResp = possiblesResp.value.shift();
        scrollToBottom();
        if (nextResp !== undefined) {
          isThereAResponse.value = true;
          currEdgardResp.value = nextResp;
        } else {
          isThereAResponse.value = false;
        }
      }      
    }

    var defaultDiacriticsRemovalMap = [
        {'base':'OE','letters':'\u008C\u0152'},
        {'base':'oe','letters':'\u009C\u0153'},
        ];

    var diacriticsMap = {};
    for (var i=0; i < defaultDiacriticsRemovalMap .length; i++){
        var letters = defaultDiacriticsRemovalMap [i].letters;
        for (var j=0; j < letters.length ; j++){
            diacriticsMap[letters[j]] = defaultDiacriticsRemovalMap [i].base;
        }
    }

    function removeDiacritics (str) {
        return str.toString().replace(/[^\u0000-\u007E]/g, function(a){
            return diacriticsMap[a] || a;
        });
    }

    return {
      voteFeedback,
      currentMessage,
      currentMore,
      shouldIDisplayVote,
      shouldIDisplayMore,
      votepositif,
    };
  },
};
</script>
<style scoped>
.showmorebtn{
  margin-bottom: -15px;
  align-self: end;
  position: absolute;
  right: 15px;
}
</style>
