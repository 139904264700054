<template>
  <div id="app">
      <v-parallax 
        height=85%
        width=50%
         :src="items[model].img"
        class="bgBlur"
        >

        <v-carousel
            cycle
            height=90vh
            hide-delimiter-background
            hide-delimiters
            show-arrows-on-hover
            v-model="model"
        >
            <template v-slot:prev="{ on, attrs }">
                <v-btn
                    fab plain
                    color="primary-lighten-1"
                    v-bind="attrs"
                    v-on="on"
                >
                <v-icon>mdi-arrow-left-bold-circle-outline</v-icon> 
                </v-btn>
            </template>   
            <template v-slot:next="{ on, attrs }">
                <v-btn
                fab plain
                color="primary-lighten-1"
                v-bind="attrs"
                v-on="on"
                >
                    <v-icon>mdi-arrow-right-bold-circle-outline</v-icon> 
                </v-btn>
            </template>
            <v-carousel-item
                v-for="(art, index) in items"
                :key="index"
                :src="art.img"
                contain
            >
                <v-row
                    class="fill-height"
                    align="end"
                    justify="center"
                >
                    <div>
                        <v-card
                            @click="art.actif ? $router.push({ path: `/edgart/${art.id_name}` }) : overlay = !overlay"
                        >
                            <v-card-title style="color:black" class="carouselcard">
                            {{art.title}}
                            </v-card-title>
                            <v-card-text>
                            {{art.artist}}
                            </v-card-text>
                            <v-btn block style="margin-top:1em">
                                Venez, je vous en dis plus
                                <v-icon>mdi-arrow-right-bold-circle-outline</v-icon>    
                            </v-btn>
                        </v-card>
                    </div>
                </v-row>
                <v-overlay :value="overlay">
                    <v-button @click="overlay = !overlay" icon>
                        Je suis encore en phase d'apprentissage sur cette oeuvre ...
                        <v-icon>mdi-close</v-icon>
                    </v-button>
                </v-overlay>
            </v-carousel-item>
        </v-carousel>
    </v-parallax>
    </div>
</template>

<script>

import { reactive } from "@vue/composition-api";

export default {
    name: "Carousel",
    data() {
        return {
        model:0,
        overlay: false,
        items: [
            {
                title: "La Joconde",
                artist: "Leonard De Vinci",
                description: "Le portrait de Mona Lisa est un tableau de l'artiste Léonard de Vinci",
                museum: "Le Louvre, Paris",
                img: require("../assets/artwork/1200px/joconde_1200px.png"),
                id: 1,
                id_name: "joconde",
                actif: true,
            },
            {
                title: "Bal du moulin de la galette",
                artist: "Auguste Renoir",
                museum: "Quai d'Orsay, Paris",
                img: require("../assets/artwork/1200px/galette_1200px.png"),
                id: 2,
                id_name: "galette",
                actif: true,
            },
            {
                title: "Les moissoneurs",
                artist: "Pieter Brueghel l'Ancien",
                description: "",
                museum: "",
                img: require("../assets/artwork/1200px/les_moissonneuses_1200px.png"),
                id: 3,
                id_name: "les_moissoneurs",
                actif: false,
            },
            {
                title: "Nighthawks",
                artist: "Edward Hopper",
                museum: "",
                img: require("../assets/artwork/1200px/nighthawks_1200px.png"),
                id: 4,
                id_name: "nighthawks",
                actif: false,
            },
            ],
    };
},
    setup() {
    const artworkList = reactive([]);
    return {
      artworkList,
    };
  },
}
</script>

<style>
.v-carousel-item{
    overflow-x: scroll;
}
.v-card__title {
    color: var(--v-primary-base);
    font-size: 1.5em;
    font-family: "TSTARBold";
}

.v-parallax__image{
    filter: blur(5px) grayscale(50%) opacity(50%);
    bottom: auto;
}
.semitransparent {
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.7035189075630253) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.description{
    font-family: "TSTARRegular";
    font-size: 20px;
  }
.Tstarlight{
    font-family: "TSTARLight";
    font-size: 20px;
  }
</style>