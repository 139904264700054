<template>
  <v-container fluid>
    <div id="app" class="ma-10">
      <div>
        <label>Rechercher une oeuvre ou un artiste:</label>
        <v-text-field class="ml-2" type="text" v-model="search" placeholder="Joconde.."></v-text-field>
      </div>
      <v-row>
        <v-col
          v-for="(art, index) in filteredList"
          :key="index"
          class="d-flex child-flex ma-10"
          cols="3"
        >
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }">
                <v-img
                  :src="art.img"
                  class="grey lighten-2"
                  @click="art.actif ? $router.push({ path: `/edgart/${art.id_name}` }) : overlay = !overlay "
                >
                </v-img>
                <v-card-subtitle class="artwork">
                  {{art.title}}
                </v-card-subtitle>
                <v-card-text class="description">
                  {{art.artist}}
                </v-card-text>
          </v-card>
        </v-hover>

          <router-view class="view"></router-view>
          <v-overlay :value="overlay">
            <v-button @click="overlay = !overlay" icon>
              Je suis encore en phase d'apprentissage sur cette oeuvre ...
              <v-icon>mdi-close</v-icon>
            </v-button>
          </v-overlay>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { reactive } from "@vue/composition-api";

export default {
  name: "Home",
  props: {
    imgsource: Object,
    close: Function,
  },

  data() {
    return {
      search: '',
      currentPic: "",
      overlay: false,
      items: [
        {
          title: "La Joconde",
          artist: "Leonard De Vinci",
          description: "Le portrait de Mona Lisa est un tableau de l'artiste Léonard de Vinci",
          museum: "Le Louvre, Paris",
          img: require("../assets/artwork/1200px/joconde_1200px.png"),
          id: 1,
          id_name: "joconde",
          actif: true,
        },
        {
          title: "Bal du moulin de la galette",
          artist: "Auguste Renoir",
          museum: "Quai d'Orsay, Paris",
          img: require("../assets/artwork/1200px/galette_1200px.png"),
          id: 2,
          id_name: "galette",
          actif: true,
        },
        {
          title: "Les moissoneurs",
          artist: "Pieter Brueghel l'Ancien",
          description: "",
          museum: "",
          img: require("../assets/artwork/1200px/les_moissonneuses_1200px.png"),
          id: 3,
          id_name: "les_moissoneurs",
          actif: false,
        },
        {
          title: "Nighthawks",
          artist: "Edward Hopper",
          museum: "",
          img: require("../assets/artwork/1200px/nighthawks_1200px.png"),
          id: 4,
          id_name: "nighthawks",
          actif: false,
        },
      ],
    };
  },
  setup() {
    const artworkList = reactive([]);
    return {
      artworkList,
    };
  },
  computed: {
    filteredList() {
      return this.items.filter(post => {
        return post.title.toLowerCase().includes(this.search.toLowerCase()) || post.artist.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
};
</script>
<style scoped>
.semitransparent {
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.7035189075630253) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.artwork{
    font-family: "TSTARBold";
    font-size: 20px;
  }
.description{
    font-family: "TSTARRegular";
    font-size: 20px;
  }
.Tstarlight{
    font-family: "TSTARLight";
    font-size: 20px;
  }
.v-card {
transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
opacity: 0.6;
 }
</style>
