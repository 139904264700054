<template>
      <v-app-bar top flat fixed app>
      <a href="/">
          <v-img :src="logoSwitch"
            max-height="48"
            max-width="100"
            contain
            />
      </a>
        <slot></slot>
      <v-spacer></v-spacer>

      <v-icon 
        class="mr-5" 
        @click="changeGalleryMode()"
      >
        mdi-checkerboard
      </v-icon>
      <v-switch 
        style="padding-top:20px"
        :value="darkMode" 
        @change="toggleDarkMode" 
        :label="`${switchLabel}`"
      ></v-switch>
    </v-app-bar>
</template>

<script>
export default {
    name: "Appbar",
    data() {
    return {
      galleryMode: false,
      darkMode: false,
      darkLogo: require("../assets/logos/Artify_Dark.png"),
      lightLogo: require("../assets/logos/Artify_Light.png"),
        }
    },
    methods: {
        toggleDarkMode: function () {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.darkMode = !this.darkMode;
        },

        changeGalleryMode: function () {
            if(this.galleryMode)
                this.$router.push({ path: `/` })
            else
                this.$router.push({ path: `/gallery` }); 
            this.galleryMode = !this.galleryMode
        }
    },
    computed: {
        switchLabel: function () {
            return this.darkMode ? 'light' : 'dark';
        },
        logoSwitch(){
        return this.darkMode ? this.darkLogo : this.lightLogo;
    }
}
}
</script>

<style>

</style>